import React from "react";
import { useMeasure } from "@uidotdev/usehooks";

import { squaresInRectangle } from "./util";
import { GalleryContext } from "./GalleryContext";

interface GalleryProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  numberOfElements: number;
}


export const Gallery = ({ numberOfElements, children, ...props }: GalleryProps) => {
  const [ref, { width, height }] = useMeasure();
  const squareWidth =
    width && height && squaresInRectangle(width, height, numberOfElements);

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        ...props.style,
      }}
    >
      <GalleryContext.Provider value={{ size: squareWidth ?? 0 }}>
        {children}
      </GalleryContext.Provider>
    </div>
  );
};
