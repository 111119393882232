import { RotationContainer } from "./RotationContainer";
import { Video } from "./Video";

interface Camera {
  stream?: MediaStream;
  rotation?: number;
  onRotate?: (angle: number) => void;
}

interface RemoteCameraProps {
  cameras: Camera[]
}


export const RemoteCamera = ({ cameras }: RemoteCameraProps) => (
  <>
    {cameras.map((camera, index) => (
      <RotationContainer key={index} rotation={camera.rotation} onRotate={camera.onRotate} >
        <Video stream={camera.stream}></Video>
      </RotationContainer>
    ))}
  </>
)