import React, { useContext } from "react";
import { io, Socket, ManagerOptions, SocketOptions } from "socket.io-client";
import { useEffect, useState } from "react";

interface Props {
  children: any;
  apikey?: string;
}

interface ContextData {
  socket?: Socket; //<ListenEvents, EmitEvents>;
  status: WebSocketStatus;
  connect: (opts: Partial<ManagerOptions & SocketOptions>) => Socket;
  disconnect: () => void;
}

const defaultData: ContextData = {
  status: "connecting",
  connect: () => { throw new Error() },
  disconnect: () => { throw new Error() },
};

const SocketContext = React.createContext<ContextData>(defaultData);

export type WebSocketStatus =
  | "not_connected"
  | "connected"
  | "connecting"
  | "disconnected";

export const WebSocketProvider = ({ children, apikey }: Props) => {
  const [status, setStatus] = useState<WebSocketStatus>("connecting");
  const [socket, setSocket] = useState<Socket | undefined>();

  const disconnect = () => {
    socket?.disconnect();
    setStatus("disconnected");
    setSocket(undefined);
  }

  const connect = (opts: Partial<ManagerOptions & SocketOptions>) => {
    if (socket) return socket;
    const sock: Socket /*<ListenEvents, EmitEvents>*/ = io(
      process.env.REACT_APP_SOCKET_URL!,
      opts
      /*{
        reconnectionAttempts: 5,
        withCredentials: true,
        extraHeaders: apikey ? { apikey } : undefined,
      }*/
    );
    sock.on("connect", async () => {
      console.log('connect')
      setStatus("connected");
    });
    sock.on("disconnect", () => {
      setStatus("disconnected");
      setSocket(undefined);
    });
    sock.on("reconnect_failed", () => {
      console.log('reconnect_failed')
      setStatus("disconnected");
      setSocket(undefined);
    });
    sock.on("connect_error", (err: Error) => {
      console.log('connect_error')
      setStatus("disconnected");
      setSocket(undefined);
    });
    setSocket(sock);
    /*return () => {
      setSocket(undefined);
      socket.disconnect();
    };*/
    return sock;
  }

  return (
    <SocketContext.Provider
      value={{
        connect,
        disconnect,
        socket,
        status,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);

export const useSocketEvent = (
  event: string, //: keyof ListenEvents,
  cb: (data: any) => void
) => {
  const data = useSocket();
  useEffect(() => {
    data.socket?.on(event, cb);
    return () => {
      data.socket?.off(event, cb);
    };
  }, [data.socket, event, cb]);
};
