import "./App.css";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import RegisterCamera from "./routes/RegisterCamera";
import Playground from "./routes/Playground";
import Finder from "./routes/Finder";
import { WebSocketProvider } from "./providers/WebSocketProvider";
import Login from "./routes/Login";
import AllCameras from "./routes/Cameras/AllCameras";
import LocalCameras from "./routes/Cameras/LocalCameras";

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: '/playground',
    element: <Playground />
  },
  {
    path: '/register',
    element: <RegisterCamera />
  },
  {
    path: '/finder',
    element: <Finder />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/cameras/local',
    element: <LocalCameras />
  },
  {
    path: '/cameras/all',
    element: <AllCameras />
  }
])

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <WebSocketProvider>
        <RouterProvider router={router} />
      </WebSocketProvider>
    </QueryClientProvider>
  );
}
