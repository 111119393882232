import React, { useEffect, useRef } from 'react';
import ZingTouch from 'zingtouch';

interface Props {
  children: any,
  onRotate?: (angle: number) => void,
  style?: React.CSSProperties,
  rotation?: number
}

export const RotationContainer = ({ children, style, onRotate, rotation }: Props) => {
  const containerRef = useRef<any>()
  const childRef = useRef<any>()
  const rotationRef = useRef<any>()

  useEffect(() => {
    rotationRef.current = rotation ?? 0;
    var activeRegion = ZingTouch.Region(containerRef.current);

    childRef.current.style.transform = 'rotate(' + rotationRef.current + 'deg)';


    activeRegion.bind(childRef.current, 'rotate', function (e: any) {
      rotationRef.current += e.detail.distanceFromLast;
      onRotate?.(rotationRef.current)
      childRef.current.style.transform = 'rotate(' + rotationRef.current + 'deg)';
    });
  }, [])

  useEffect(() => {
    rotationRef.current = rotation ?? 0;
    childRef.current.style.transform = 'rotate(' + rotation + 'deg)';
  }, [rotation])

  return (
    <div ref={containerRef} style={{ backgroundColor: 'black', overflow: 'hidden', ...style }}>
      <div ref={childRef}>
        {children}
      </div>
    </div>
  )
}