import React, { useState } from 'react';
import { Button, TextField, Grid, Paper, Typography, Box } from '@mui/material';
import { userApi } from "../api";
import { useNavigate } from 'react-router-dom';



function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();


  const handleLogin = async () => {
    try {
      await userApi.login({
        email: email,
        password: password,
      });
      navigate('/cameras/all');
    } catch (error) {
      setErrorMessage('Login failed. Please check your email and password.');
    }
  };


  return (
    <Box flex={1} display="flex" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Paper style={{ padding: 16 }}>
          <Typography variant="h5" component="h2">
            Login
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setErrorMessage('');
            }}
            error={!!errorMessage}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Password"
            type="password"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
              setErrorMessage('');
            }}
            error={!!errorMessage}
            helperText={errorMessage}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogin}
          >
            Login
          </Button>
        </Paper>
      </Grid>
    </Box>
  );
}

export default Login;