/**
 * Computes squares that can be fitted with a rectangle of
 * given width and height.
 *
 * @param      {number}  w       Width of the rectangle
 * @param      {number}  h       Height of the rectangle
 * @param      {number}  n       Approximate number of squares to fit
 * @return     {number}  height/width of the squares
 */
export const squaresInRectangle = (w: number, h: number, n: number) => {
  let sx, sy;

  const px = Math.ceil(Math.sqrt((n * w) / h)); //?

  if (Math.floor((px * h) / w) * px < n) {
    sx = h / Math.ceil((px * h) / w);
  } else {
    sx = w / px;
  }

  const py = Math.ceil(Math.sqrt((n * h) / w)); //?

  if (Math.floor((py * w) / h) * py < n) {
    sy = w / Math.ceil((w * py) / h);
  } else {
    sy = h / py;
  }

  return Math.max(sx, sy);
}