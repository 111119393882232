import { WebSocketProvider } from "../../providers/WebSocketProvider";
import Cameras from "./Cameras";

function Finder() {
  return (
    <div className="App">
      <WebSocketProvider apikey="9wAiEsMnExxH98pc3pUW">
        <Cameras />
      </WebSocketProvider>
    </div>
  );

}

export default Finder;
