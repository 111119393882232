import React, { useCallback, useEffect, useRef, useState } from "react";

import { BrowserQRCodeReader, IScannerControls } from "@zxing/browser";
import { DecodeContinuouslyCallback } from "@zxing/browser/esm/common/DecodeContinuouslyCallback";
import CameraSelect from "../../../components/CameraSelect";
import { RotatedVideo } from "../../../components/Video";
import { Status as StatusType, useLocalCameraManager } from "./useLocalCameraManager";
import { Gallery } from "../../../components/Gallery";
import storage from "../../../storage";

const tokenFromStorage = storage.getToken();

interface StatusProps {
  status: StatusType;
  user: { name: string } | null;
  onDisconnect: () => void;
}

const Status = (props: StatusProps) => {
  let text = "Initialisierung...";
  let right: JSX.Element | undefined = undefined;

  if (props.status === "connected") {
    text = `Gekoppelt mit ${props.user?.name}`;
    right = (
      <button
        style={{
          fontSize: 20,
          marginLeft: 20,
          alignSelf: "center",
          display: "flex",
        }}
        onClick={props.onDisconnect}
      >
        Trennen
      </button>
    );
  } else if (props.status === "scanning") {
    text = "QR-Code scannen";
  } else if (props.status === "connecting") {
    text = "Verbindung wird aufgebaut...";
  } else if (props.status === "disconnected") {
    text = "Verbindung wird aufgebaut...";
  }
  return (
    <div
      style={{
        display: "flex",
        alignSelf: "center",
        flexWrap: "nowrap",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
        minHeight: 0,
      }}
    >
      <p style={{ fontSize: 20, color: "#FFFFFF" }}>{text}</p>
      {right}
    </div>
  );
};

const qrReader = new BrowserQRCodeReader();

export default function LocalCameras() {
  const [token, setToken] = useState(tokenFromStorage);
  const saveToken = useCallback((token: string | null) => {
    console.log("Saving token", token);
    setToken(token);
    token
      ? storage.setToken(token)
      : storage.removeToken();
  }, []);

  const {
    cameras,
    selectedCameras,
    selectedCameraId,
    startStreams,
    stopStreams,
    status,
    register,
    connect,
    user,
    deregister,
    onRotate,
    setStatus
  } = useLocalCameraManager(saveToken);
  const controls = useRef<IScannerControls>();

  useEffect(() => {
    console.log('FOO', selectedCameras, status, token)
    if (
      selectedCameras.length &&
      status === "disconnected"
    ) {
      if (token) {
        connect(token);
      } else {
        setStatus("scanning")
        const handleBarCodeScanned: DecodeContinuouslyCallback = (
          result,
          error
        ) => {
          if (result) {
            controls.current?.stop();
            stopStreams();
            startStreams(cameras, selectedCameraId!).then(() => { register(result.getText()) });
          }
        };
        const handleToken = async () => {
          console.log("Waiting for QR-Code");
          controls.current = await qrReader.decodeFromStream(
            selectedCameras[0].stream!,
            undefined,
            handleBarCodeScanned
          );
        };
        handleToken();
      }
    }
  }, [selectedCameras.map((c) => c.id).join(","), token]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        background: "#000000",
        flexDirection: "column",
        minWidth: 0,
      }}
    >
      <CameraSelect
        cameras={cameras}
        selectedCamera={selectedCameraId}
        onChange={(selected) => startStreams(cameras, selected)}
      />
      <Gallery
        style={{
          flex: 1,
          minHeight: 0,
          margin: 20,
        }}
        numberOfElements={selectedCameras.length}
      >
        {selectedCameras.map((camera, index) => (
          <RotatedVideo
            key={camera.id}
            stream={camera.stream}
            onRotate={(angle) => onRotate(camera.id, index, angle)}
            rotation={camera.rotation}
          />
        ))}

        {selectedCameras.length === 0 && (
          <div
            style={{
              background: "#000000",
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </Gallery>
      <Status
        status={status}
        user={user}
        onDisconnect={() => deregister(token!)}
      />
    </div>
  );
}
