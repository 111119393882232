import { Gallery } from "../../components/Gallery";
import { useRemoteCameras } from "../Cameras/AllCameras/useRemoteCameras";
import { RemoteCamera } from "../../components/RemoteCamera";

interface Cam { id: string, connected?: number }

export default function Cameras() {
  const { cameras } = useRemoteCameras(false)
  const numberOfElements = cameras.reduce((acc, cam) => acc + cam.cameras.length, 0)
  return (
    <Gallery
      style={{
        flex: 1,
        minHeight: 0,
      }}
      numberOfElements={numberOfElements}
    >
      {cameras.map((cam, i) => (
        <RemoteCamera key={i} cameras={cam.cameras} />
      ))}
    </Gallery>
  );
}
