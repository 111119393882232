"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaStream = exports.RTCPeerConnection = exports.permissions = exports.mediaDevices = exports.MediaStreamTrack = exports.RTCSessionDescription = exports.RTCIceCandidate = void 0;
const { RTCPeerConnection, RTCIceCandidate, RTCSessionDescription, MediaStream, MediaStreamTrack, } = window;
exports.RTCPeerConnection = RTCPeerConnection;
exports.RTCIceCandidate = RTCIceCandidate;
exports.RTCSessionDescription = RTCSessionDescription;
exports.MediaStream = MediaStream;
exports.MediaStreamTrack = MediaStreamTrack;
const { mediaDevices, permissions } = navigator;
exports.mediaDevices = mediaDevices;
exports.permissions = permissions;
