import React from "react";


interface ContextData {
  size: number;
}

const defaultData: ContextData = {} as ContextData;
export const GalleryContext = React.createContext<ContextData>(defaultData);

export const useItemSize = () => {
  const context = React.useContext(GalleryContext);
  if (!context) {
    throw new Error("useItemSize must be used within a GalleryContext");
  }
  return context.size;
}
