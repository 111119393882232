import { ConnectionManager, Signaling } from "@dartnet/connection";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { useLocalCameras } from "./useLocalCameras";
import { Camera } from "../../../interfaces";
import storage from "../../../storage";
import { useSocket } from "../../../providers/WebSocketProvider";

export type Status = "disconnected" | "connecting" | "connected" | "scanning";

const axiosInstance = axios.create({
  baseURL: process.env.EXPO_PUBLIC_API_URL || process.env.REACT_APP_API_URL,
});

export const useLocalCameraManager = (
  onToken: (token: string | null) => void
) => {
  const [status, setStatus] = useState<Status>("disconnected");
  const [user, setUser] = useState<{ name: string } | null>(null);
  const manager = useRef<ConnectionManager>();
  const { socket, connect: connectSocket, disconnect: disconnectSocket } = useSocket();

  const {
    cameras,
    selectedCameras,
    selectedCameraId,
    startStreams,
    stopStreams,
    updateCameraState,
  } = useLocalCameras();

  const register = async (joinToken: string) => {
    console.log("registering");
    setStatus("connecting");
    const { data } = await axiosInstance({
      url: "/cameras/register",
      method: "POST",
      headers: { "Camera-Token": joinToken },
    });
    onToken(data.token);
    connect(data.token);
  };

  const startTheStreams = async (cameras: Camera[], selected: string) => {
    if (status === 'connected') {
      await disconnect()
    }
    await startStreams(cameras, selected)
  };

  useEffect(() => {
    manager.current?.onConnection((connection) => {
      for (const camera of selectedCameras) {
        connection?.updateState({
          cameraIndex: camera.index,
          rotation: camera.rotation,
        });
      }
    })
  }, [selectedCameras])

  const connect = async (token: string) => {
    setStatus("connecting");
    console.log("connecting");
    const socket = connectSocket({
      auth: { token },
      extraHeaders: {
        "camera-count": selectedCameras.length.toString(),
      },
    })
    const signaling = new Signaling(socket);
    manager.current = new ConnectionManager(signaling);
    for (const camera of selectedCameras) {
      // @ts-ignore
      manager.current.addVideoStream(camera.stream);
    }
    manager.current.onStateUpdate((peerId, state) => updateCameraState(state));
    manager.current.onConnection((connection) => {
      for (let i = 0; i < selectedCameras.length; i++) {
        connection?.updateState({
          cameraIndex: i,
          rotation: selectedCameras[i].rotation,
        });
      }
    })
    manager.current.waitForOffers();
    socket.once("unauthorized", () => {
      console.log("unauthorized");
      setStatus("scanning");
      onToken(null);
    });
    socket.once("authorized", async (data: any) => {
      console.log("connected", data);
      setStatus("connected");
      setUser(data.user);
      onToken(token);
    });
    console.log('waitForOffers')
  };

  const deregister = async (token: string) => {
    console.log('deregistering')
    await axiosInstance({
      url: "/cameras/deregister",
      method: "POST",
      headers: { "Camera-Token": token },
    });
    setStatus("scanning");
    onToken(null);
  };

  const disconnect = async () => {
    console.log('disconnecting')
    if (manager.current) {
      for (const connection of manager.current.connections) {
        await manager.current.stopCall(connection.id!);
      }
      manager.current = undefined
      console.log('calls stopped')
    }
    if (socket) {
      disconnectSocket();
      console.log('socket closed')
    }
    setStatus("disconnected");
    console.log('disconnected')
  };

  const onRotate = (id: string, index: number, angle: number) => {
    storage.setRotation(id, angle);
    updateCameraState({
      cameraIndex: index,
      rotation: angle
    })
    manager.current?.connections.forEach((connection) => {
      connection.updateState({
        cameraIndex: index,
        rotation: angle
      })
    })
  }

  return {
    cameras,
    startStreams: startTheStreams,
    stopStreams,
    register,
    connect,
    disconnect,
    deregister,
    status,
    setStatus,
    user,
    selectedCameraId,
    selectedCameras,
    onRotate,
  };
};
