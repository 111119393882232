import { useEffect, useMemo, useState } from "react";
import { Camera, DeepPartial } from "../../../interfaces";
import { CamearaState } from "@dartnet/connection";
import storage from "../../../storage";

export const getVideoDevices = async () => {
  const devices =
    (await navigator.mediaDevices.enumerateDevices()) as MediaDeviceInfo[];
  const cameras = devices.filter((device) => device.kind === "videoinput");
  return cameras;
};

export const getDevices = async () => {
  let cameras = await getVideoDevices();

  // If this camera permission is not given yet
  // all labels will be empty
  if (!cameras.find((cam) => cam.label)) {
    // Ask for permission
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    stream.getTracks().forEach(function (track) {
      track.stop();
    });
    cameras = await getVideoDevices();
  }

  return cameras;
};

export const getDefaultCameraId = (cameras: Camera[]) => {
  const cameraId = storage.getCameraId();
  if (cameraId && cameras.find(cam => cam.id === cameraId)) return cameraId;
  if (cameras.length === 2) {
    const back = cameras.find((camera) => camera.label?.includes("back"));
    const front = cameras.find((camera) => camera.label?.includes("front"));

    if (back && front) {
      return back.id;
    }
  }
  return "all";
};

export const getLocalCameras = async () => {
  const devices = await getDevices();
  console.log("FILTERED DEVICES", devices);
  return devices.map((camera, index) => {
    return {
      id: camera.deviceId,
      label: camera.label || `Camera ${index + 1}`,
      index,
      isRemote: false,
      rotation: storage.getRotation(camera.deviceId),
    };
  });
};

const stopCameras = (cameras: Camera[]) => {
  cameras.forEach((camera) => {
    camera.stream?.getTracks().forEach((track) => {
      track.stop();
    });
    camera.stream = undefined;
  });
};


export const useLocalCameras = () => {
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [selectedCameraId, setSelectedCameraId] = useState<string>();

  const selectedCameras = useMemo(() => cameras.filter((camera) => {
    if (!camera.stream) return false
    return selectedCameraId === "all" || camera.id === selectedCameraId;
  }), [cameras, selectedCameraId]);

  useEffect(() => {
    const init = async () => {
      const cameras = await getLocalCameras();
      setCameras(cameras);
      const selected = getDefaultCameraId(cameras);
      await startStreams(cameras, selected);
    };
    init();
  }, []);

  const startStreams = async (cameras: Camera[], selected: string) => {
    storage.setCameraId(selected);
    console.log("START STREAMS", cameras, selected);
    const cams = await Promise.all(
      cameras.map(async (camera, index) => {
        const isSelected = selected === "all" || camera.id === selected
        if (!isSelected) {
          if (!camera.stream) return camera
          console.log("Stopping Stream", camera.id, camera.stream);
          camera.stream.getTracks().forEach((track) => {
            track.stop();
          });
          return {
            ...camera,
            stream: undefined,
          }
        }
        if (camera.stream) return camera;
        console.log("GETTING USER MEDIA");
        let stream;
        const constraints = {
          audio: false,
          video: {
            frameRate: 30,
            width: { ideal: 1920 },
            height: { ideal: 1080 },
          } as MediaTrackConstraints,
        };
        if (camera.id) {
          constraints.video.deviceId = camera.id;
        } else {
          constraints.video.facingMode = { exact: "environment" };
          // @ts-ignore
          constraints.video.zoom = { ideal: 1.0 };
        }
        try {
          stream = await navigator.mediaDevices.getUserMedia(constraints);
          console.log("GOT USER MEDIA", camera.id, stream.id, stream.active);
        } catch (err) {
          console.log("ERROR GETTING USER MEDIA");
          return camera;
        }
        let zoomCap, zoomSettings;
        try {
          //@ts-ignore
          zoomCap = stream.getVideoTracks()[0].getCapabilities().zoom;
          //@ts-ignore
          zoomSettings = stream.getVideoTracks()[0].getSettings().zoom;
        } catch (err) {
          zoomCap = null;
          zoomSettings = null;
        }
        return {
          ...camera,
          stream,
          zoom: zoomCap && {
            min: zoomCap.min,
            max: zoomCap.max,
            step: zoomCap.step,
            value: zoomSettings,
          },
        };
      })
    );
    setSelectedCameraId(selected);
    setCameras([...cams]);
    console.log("STARTED STREAMS", cams, selected);
  };

  const stopStreams = () => {
    stopCameras(cameras);
    setCameras([...cameras]);
  };

  const updateCameraState = (state: DeepPartial<CamearaState>) => {
    const camera = selectedCameras[state.cameraIndex!];
    if (state.rotation !== undefined) {
      storage.setRotation(camera.id, state.rotation);
    }
    setCameras((cameras) => {
      const selectedCameras = cameras.filter((camera) => {
        if (!camera.stream) return false
        return selectedCameraId === "all" || camera.id === selectedCameraId;
      })
      const camera = selectedCameras[state.cameraIndex!];
      if (camera?.index !== undefined && cameras[camera.index]) {
        cameras[camera?.index] = {
          ...cameras[camera?.index],
          rotation: state.rotation ?? cameras[camera?.index].rotation,
        };
        return [...cameras];
      }
      return cameras;
    });
  };

  return {
    cameras,
    selectedCameras,
    updateCameraState,
    startStreams,
    stopStreams,
    selectedCameraId,
  };
};
