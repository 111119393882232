import { Gallery } from "../../../components/Gallery";
import { RemoteCamera } from "../../../components/RemoteCamera";
import { useRemoteCameras } from "./useRemoteCameras";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../../providers/WebSocketProvider";
import { useEffect } from "react";


export default function AllCameras() {
  const { socket, connect } = useSocket();
  const navigate = useNavigate();
  const { cameras } = useRemoteCameras();
  const numberOfElements = cameras.reduce((acc, cam) => acc + cam.cameras.length, 0);
  useEffect(() => {
    connect({
      reconnectionAttempts: 5,
      withCredentials: true,
    })
  }, [])
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        background: "#000000",
        flexDirection: "column",
        minWidth: 0,
      }}
    >
      <Gallery
        style={{
          flex: 1,
          minHeight: 0,
        }}
        numberOfElements={numberOfElements}
      >
        {cameras.map((cam, i) => (
          <RemoteCamera key={i} cameras={cam.cameras} />
        ))}
      </Gallery>
      <Fab style={{ right: 25, bottom: 25, position: "fixed" }} onClick={() => navigate('/register')} color="primary" aria-label="add">
        <AddIcon />
      </Fab>
    </div>
  );
}
