/* tslint:disable */
/* eslint-disable */
/**
 * DartNet
 * DartNet API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AllowJoinResponse
 */
export interface AllowJoinResponse {
    /**
     * 
     * @type {string}
     * @memberof AllowJoinResponse
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface Camera
 */
export interface Camera {
    /**
     * 
     * @type {string}
     * @memberof Camera
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Camera
     */
    'rotation': number;
    /**
     * 
     * @type {number}
     * @memberof Camera
     */
    'connected'?: number;
}
/**
 * 
 * @export
 * @interface CameraCreateResponse
 */
export interface CameraCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof CameraCreateResponse
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CameraCreateResponse
     */
    'rotation': number;
    /**
     * 
     * @type {number}
     * @memberof CameraCreateResponse
     */
    'connected'?: number;
    /**
     * 
     * @type {string}
     * @memberof CameraCreateResponse
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface CameraUpdate
 */
export interface CameraUpdate {
    /**
     * 
     * @type {number}
     * @memberof CameraUpdate
     */
    'rotation': number;
}
/**
 * 
 * @export
 * @interface Cameras
 */
export interface Cameras {
    /**
     * 
     * @type {Array<Camera>}
     * @memberof Cameras
     */
    'cameras': Array<Camera>;
}
/**
 * 
 * @export
 * @interface GameDetails
 */
export interface GameDetails {
    /**
     * 
     * @type {string}
     * @memberof GameDetails
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GameDetails
     */
    'type': GameDetailsTypeEnum;
    /**
     * 
     * @type {Array<ParticipantDetails>}
     * @memberof GameDetails
     */
    'participants': Array<ParticipantDetails>;
}

export const GameDetailsTypeEnum = {
    X01: 'X01',
    Cricket: 'Cricket',
    FreePlay: 'FreePlay',
    AroundTheClock: 'AroundTheClock'
} as const;

export type GameDetailsTypeEnum = typeof GameDetailsTypeEnum[keyof typeof GameDetailsTypeEnum];

/**
 * 
 * @export
 * @interface GameState
 */
export interface GameState {
    /**
     * 
     * @type {string}
     * @memberof GameState
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GameState
     */
    'sessionId': string;
    /**
     * 
     * @type {object}
     * @memberof GameState
     */
    'lastRound': object;
    /**
     * 
     * @type {number}
     * @memberof GameState
     */
    'currentPlayerIndex': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GameState
     */
    'winners': Array<number>;
}
/**
 * 
 * @export
 * @interface Games
 */
export interface Games {
    /**
     * 
     * @type {Array<object>}
     * @memberof Games
     */
    'items': Array<object>;
    /**
     * 
     * @type {number}
     * @memberof Games
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof Games
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof Games
     */
    'offset': number;
}
/**
 * 
 * @export
 * @interface GuestCreation
 */
export interface GuestCreation {
    /**
     * 
     * @type {string}
     * @memberof GuestCreation
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GuestList
 */
export interface GuestList {
    /**
     * 
     * @type {Array<GuestResult>}
     * @memberof GuestList
     */
    'items': Array<GuestResult>;
}
/**
 * 
 * @export
 * @interface GuestResult
 */
export interface GuestResult {
    /**
     * 
     * @type {string}
     * @memberof GuestResult
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GuestResult
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GuestResult
     */
    'image'?: string;
}
/**
 * 
 * @export
 * @interface HeatmapItem
 */
export interface HeatmapItem {
    /**
     * 
     * @type {number}
     * @memberof HeatmapItem
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof HeatmapItem
     */
    'multiplier': number;
    /**
     * 
     * @type {number}
     * @memberof HeatmapItem
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ParticipantDetails
 */
export interface ParticipantDetails {
    /**
     * 
     * @type {string}
     * @memberof ParticipantDetails
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDetails
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDetails
     */
    'image': string;
    /**
     * 
     * @type {Array<HeatmapItem>}
     * @memberof ParticipantDetails
     */
    'heatmap': Array<HeatmapItem>;
    /**
     * 
     * @type {Array<Stat>}
     * @memberof ParticipantDetails
     */
    'stats': Array<Stat>;
    /**
     * 
     * @type {Array<ScoreHistoryItem>}
     * @memberof ParticipantDetails
     */
    'scoreHistory': Array<ScoreHistoryItem>;
}
/**
 * 
 * @export
 * @interface ScoreHistoryItem
 */
export interface ScoreHistoryItem {
    /**
     * 
     * @type {number}
     * @memberof ScoreHistoryItem
     */
    'score': number;
    /**
     * 
     * @type {number}
     * @memberof ScoreHistoryItem
     */
    'index': number;
}
/**
 * 
 * @export
 * @interface Stat
 */
export interface Stat {
    /**
     * 
     * @type {string}
     * @memberof Stat
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Stat
     */
    'value': number;
    /**
     * 
     * @type {boolean}
     * @memberof Stat
     */
    'percent': boolean;
}
/**
 * 
 * @export
 * @interface TokenObject
 */
export interface TokenObject {
    /**
     * 
     * @type {string}
     * @memberof TokenObject
     */
    'access_token': string;
}
/**
 * 
 * @export
 * @interface UserCreation
 */
export interface UserCreation {
    /**
     * 
     * @type {string}
     * @memberof UserCreation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreation
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UserInfoReduces
 */
export interface UserInfoReduces {
    /**
     * 
     * @type {string}
     * @memberof UserInfoReduces
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoReduces
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoReduces
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'status': UserProfileStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'image'?: string;
}

export const UserProfileStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type UserProfileStatusEnum = typeof UserProfileStatusEnum[keyof typeof UserProfileStatusEnum];

/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'status'?: UserUpdateStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'role'?: UserUpdateRoleEnum;
}

export const UserUpdateStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type UserUpdateStatusEnum = typeof UserUpdateStatusEnum[keyof typeof UserUpdateStatusEnum];
export const UserUpdateRoleEnum = {
    Admin: 'admin',
    Player: 'player'
} as const;

export type UserUpdateRoleEnum = typeof UserUpdateRoleEnum[keyof typeof UserUpdateRoleEnum];


/**
 * CameraApi - axios parameter creator
 * @export
 */
export const CameraApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowJoin: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/join/on`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCamera: async (cameraId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('deleteCamera', 'cameraId', cameraId)
            const localVarPath = `/cameras/{cameraId}`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cameraToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deregisterCamera: async (cameraToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraToken' is not null or undefined
            assertParamExists('deregisterCamera', 'cameraToken', cameraToken)
            const localVarPath = `/cameras/deregister`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cameraToken != null) {
                localVarHeaderParameter['Camera-Token'] = String(cameraToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disallowJoin: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras/join/off`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCamera: async (cameraId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getCamera', 'cameraId', cameraId)
            const localVarPath = `/cameras/{cameraId}`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameras: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cameraToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCamera: async (cameraToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraToken' is not null or undefined
            assertParamExists('registerCamera', 'cameraToken', cameraToken)
            const localVarPath = `/cameras/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cameraToken != null) {
                localVarHeaderParameter['Camera-Token'] = String(cameraToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cameraId 
         * @param {CameraUpdate} cameraUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCamera: async (cameraId: string, cameraUpdate: CameraUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('updateCamera', 'cameraId', cameraId)
            // verify required parameter 'cameraUpdate' is not null or undefined
            assertParamExists('updateCamera', 'cameraUpdate', cameraUpdate)
            const localVarPath = `/cameras/{cameraId}`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cameraUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraApi - functional programming interface
 * @export
 */
export const CameraApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allowJoin(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllowJoinResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allowJoin(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CameraApi.allowJoin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCamera(cameraId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCamera(cameraId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CameraApi.deleteCamera']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cameraToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deregisterCamera(cameraToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deregisterCamera(cameraToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CameraApi.deregisterCamera']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disallowJoin(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disallowJoin(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CameraApi.disallowJoin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCamera(cameraId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Camera>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCamera(cameraId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CameraApi.getCamera']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameras(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cameras>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameras(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CameraApi.getCameras']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cameraToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerCamera(cameraToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerCamera(cameraToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CameraApi.registerCamera']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cameraId 
         * @param {CameraUpdate} cameraUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCamera(cameraId: string, cameraUpdate: CameraUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCamera(cameraId, cameraUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CameraApi.updateCamera']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CameraApi - factory interface
 * @export
 */
export const CameraApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowJoin(options?: any): AxiosPromise<AllowJoinResponse> {
            return localVarFp.allowJoin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCamera(cameraId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCamera(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cameraToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deregisterCamera(cameraToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.deregisterCamera(cameraToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disallowJoin(options?: any): AxiosPromise<void> {
            return localVarFp.disallowJoin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCamera(cameraId: string, options?: any): AxiosPromise<Camera> {
            return localVarFp.getCamera(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameras(options?: any): AxiosPromise<Cameras> {
            return localVarFp.getCameras(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cameraToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCamera(cameraToken: string, options?: any): AxiosPromise<CameraCreateResponse> {
            return localVarFp.registerCamera(cameraToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cameraId 
         * @param {CameraUpdate} cameraUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCamera(cameraId: string, cameraUpdate: CameraUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateCamera(cameraId, cameraUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraApi - object-oriented interface
 * @export
 * @class CameraApi
 * @extends {BaseAPI}
 */
export class CameraApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraApi
     */
    public allowJoin(options?: RawAxiosRequestConfig) {
        return CameraApiFp(this.configuration).allowJoin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraApi
     */
    public deleteCamera(cameraId: string, options?: RawAxiosRequestConfig) {
        return CameraApiFp(this.configuration).deleteCamera(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cameraToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraApi
     */
    public deregisterCamera(cameraToken: string, options?: RawAxiosRequestConfig) {
        return CameraApiFp(this.configuration).deregisterCamera(cameraToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraApi
     */
    public disallowJoin(options?: RawAxiosRequestConfig) {
        return CameraApiFp(this.configuration).disallowJoin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraApi
     */
    public getCamera(cameraId: string, options?: RawAxiosRequestConfig) {
        return CameraApiFp(this.configuration).getCamera(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraApi
     */
    public getCameras(options?: RawAxiosRequestConfig) {
        return CameraApiFp(this.configuration).getCameras(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cameraToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraApi
     */
    public registerCamera(cameraToken: string, options?: RawAxiosRequestConfig) {
        return CameraApiFp(this.configuration).registerCamera(cameraToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cameraId 
     * @param {CameraUpdate} cameraUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraApi
     */
    public updateCamera(cameraId: string, cameraUpdate: CameraUpdate, options?: RawAxiosRequestConfig) {
        return CameraApiFp(this.configuration).updateCamera(cameraId, cameraUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FirmwareApi - axios parameter creator
 * @export
 */
export const FirmwareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ifNoneMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEspFirmware: async (ifNoneMatch: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifNoneMatch' is not null or undefined
            assertParamExists('getEspFirmware', 'ifNoneMatch', ifNoneMatch)
            const localVarPath = `/firmware/esp32`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ifNoneMatch != null) {
                localVarHeaderParameter['If-None-Match'] = String(ifNoneMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirmwareApi - functional programming interface
 * @export
 */
export const FirmwareApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirmwareApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ifNoneMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEspFirmware(ifNoneMatch: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEspFirmware(ifNoneMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FirmwareApi.getEspFirmware']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FirmwareApi - factory interface
 * @export
 */
export const FirmwareApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirmwareApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ifNoneMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEspFirmware(ifNoneMatch: string, options?: any): AxiosPromise<void> {
            return localVarFp.getEspFirmware(ifNoneMatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FirmwareApi - object-oriented interface
 * @export
 * @class FirmwareApi
 * @extends {BaseAPI}
 */
export class FirmwareApi extends BaseAPI {
    /**
     * 
     * @param {string} ifNoneMatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmwareApi
     */
    public getEspFirmware(ifNoneMatch: string, options?: RawAxiosRequestConfig) {
        return FirmwareApiFp(this.configuration).getEspFirmware(ifNoneMatch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GameApi - axios parameter creator
 * @export
 */
export const GameApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGame: async (gameId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            assertParamExists('deleteGame', 'gameId', gameId)
            const localVarPath = `/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveGame: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/games/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGame: async (gameId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            assertParamExists('getGame', 'gameId', gameId)
            const localVarPath = `/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGames: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/games`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GameApi - functional programming interface
 * @export
 */
export const GameApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GameApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGame(gameId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGame(gameId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GameApi.deleteGame']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveGame(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveGame(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GameApi.getActiveGame']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGame(gameId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGame(gameId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GameApi.getGame']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGames(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Games>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGames(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GameApi.getGames']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GameApi - factory interface
 * @export
 */
export const GameApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GameApiFp(configuration)
    return {
        /**
         * 
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGame(gameId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGame(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveGame(options?: any): AxiosPromise<GameState> {
            return localVarFp.getActiveGame(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGame(gameId: string, options?: any): AxiosPromise<GameDetails> {
            return localVarFp.getGame(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGames(limit?: number, offset?: number, options?: any): AxiosPromise<Games> {
            return localVarFp.getGames(limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GameApi - object-oriented interface
 * @export
 * @class GameApi
 * @extends {BaseAPI}
 */
export class GameApi extends BaseAPI {
    /**
     * 
     * @param {string} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public deleteGame(gameId: string, options?: RawAxiosRequestConfig) {
        return GameApiFp(this.configuration).deleteGame(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public getActiveGame(options?: RawAxiosRequestConfig) {
        return GameApiFp(this.configuration).getActiveGame(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public getGame(gameId: string, options?: RawAxiosRequestConfig) {
        return GameApiFp(this.configuration).getGame(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public getGames(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return GameApiFp(this.configuration).getGames(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GuestApi - axios parameter creator
 * @export
 */
export const GuestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GuestCreation} guestCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGuest: async (guestCreation: GuestCreation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guestCreation' is not null or undefined
            assertParamExists('addGuest', 'guestCreation', guestCreation)
            const localVarPath = `/guests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(guestCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuest: async (guestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guestId' is not null or undefined
            assertParamExists('deleteGuest', 'guestId', guestId)
            const localVarPath = `/guests/{guestId}`
                .replace(`{${"guestId"}}`, encodeURIComponent(String(guestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuests: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/guests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuestApi - functional programming interface
 * @export
 */
export const GuestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GuestCreation} guestCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGuest(guestCreation: GuestCreation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGuest(guestCreation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuestApi.addGuest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGuest(guestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGuest(guestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuestApi.deleteGuest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuests(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuests(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuestApi.getGuests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GuestApi - factory interface
 * @export
 */
export const GuestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuestApiFp(configuration)
    return {
        /**
         * 
         * @param {GuestCreation} guestCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGuest(guestCreation: GuestCreation, options?: any): AxiosPromise<void> {
            return localVarFp.addGuest(guestCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuest(guestId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGuest(guestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuests(options?: any): AxiosPromise<GuestList> {
            return localVarFp.getGuests(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuestApi - object-oriented interface
 * @export
 * @class GuestApi
 * @extends {BaseAPI}
 */
export class GuestApi extends BaseAPI {
    /**
     * 
     * @param {GuestCreation} guestCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestApi
     */
    public addGuest(guestCreation: GuestCreation, options?: RawAxiosRequestConfig) {
        return GuestApiFp(this.configuration).addGuest(guestCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestApi
     */
    public deleteGuest(guestId: string, options?: RawAxiosRequestConfig) {
        return GuestApiFp(this.configuration).deleteGuest(guestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestApi
     */
    public getGuests(options?: RawAxiosRequestConfig) {
        return GuestApiFp(this.configuration).getGuests(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetric: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetric(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetric(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.getMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetric(options?: any): AxiosPromise<string> {
            return localVarFp.getMetric(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getMetric(options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getMetric(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopSession: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSession(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSession(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SessionApi.createSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopSession(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopSession(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SessionApi.stopSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession(options?: any): AxiosPromise<void> {
            return localVarFp.createSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopSession(options?: any): AxiosPromise<void> {
            return localVarFp.stopSession(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public createSession(options?: RawAxiosRequestConfig) {
        return SessionApiFp(this.configuration).createSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public stopSession(options?: RawAxiosRequestConfig) {
        return SessionApiFp(this.configuration).stopSession(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TrainingApi - axios parameter creator
 * @export
 */
export const TrainingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/training/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {string} dartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: async (sessionId: string, dartId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('uploadImage', 'sessionId', sessionId)
            // verify required parameter 'dartId' is not null or undefined
            assertParamExists('uploadImage', 'dartId', dartId)
            const localVarPath = `/training/sessions/{sessionId}/{dartId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"dartId"}}`, encodeURIComponent(String(dartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainingApi - functional programming interface
 * @export
 */
export const TrainingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingApi.getSessions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {string} dartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImage(sessionId: string, dartId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(sessionId, dartId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingApi.uploadImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TrainingApi - factory interface
 * @export
 */
export const TrainingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessions(options?: any): AxiosPromise<void> {
            return localVarFp.getSessions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {string} dartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(sessionId: string, dartId: string, options?: any): AxiosPromise<void> {
            return localVarFp.uploadImage(sessionId, dartId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainingApi - object-oriented interface
 * @export
 * @class TrainingApi
 * @extends {BaseAPI}
 */
export class TrainingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    public getSessions(options?: RawAxiosRequestConfig) {
        return TrainingApiFp(this.configuration).getSessions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {string} dartId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    public uploadImage(sessionId: string, dartId: string, options?: RawAxiosRequestConfig) {
        return TrainingApiFp(this.configuration).uploadImage(sessionId, dartId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (login: Login, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('login', 'login', login)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCreation} userCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (userCreation: UserCreation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreation' is not null or undefined
            assertParamExists('register', 'userCreation', userCreation)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, userUpdate: UserUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'userUpdate' is not null or undefined
            assertParamExists('updateUser', 'userUpdate', userUpdate)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(login: Login, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(login, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserCreation} userCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(userCreation: UserCreation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoReduces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(userCreation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.register']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, userUpdate: UserUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoReduces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, userUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMe(options?: any): AxiosPromise<void> {
            return localVarFp.deleteMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<UserProfile> {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login: Login, options?: any): AxiosPromise<TokenObject> {
            return localVarFp.login(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCreation} userCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(userCreation: UserCreation, options?: any): AxiosPromise<UserInfoReduces> {
            return localVarFp.register(userCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, userUpdate: UserUpdate, options?: any): AxiosPromise<UserInfoReduces> {
            return localVarFp.updateUser(userId, userUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteMe(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMe(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public login(login: Login, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).login(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserCreation} userCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public register(userCreation: UserCreation, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).register(userCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UserUpdate} userUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(userId: string, userUpdate: UserUpdate, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(userId, userUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



