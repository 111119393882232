"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
class Signaling {
    constructor(socket) {
        this.socket = socket;
    }
    sendOffer(offer, to) {
        return __awaiter(this, void 0, void 0, function* () {
            this.socket.emit("offer", { offer, to });
        });
    }
    sendAnswer(answer, to) {
        return __awaiter(this, void 0, void 0, function* () {
            this.socket.emit("answer", { answer, to });
        });
    }
    sendCandidate(candidate, to) {
        return __awaiter(this, void 0, void 0, function* () {
            this.socket.emit("candidate", { candidate, to });
        });
    }
    stop(peerId) {
        this.socket.emit('stop', { to: peerId });
    }
    onOffer(cb) {
        this.socket.on('offer', ({ offer, from }) => cb(offer, from));
    }
    onAnswer(cb) {
        this.socket.on('answer', ({ answer, from }) => cb(answer, from));
    }
    onCandidate(cb) {
        this.socket.on('candidate', ({ candidate, from }) => cb(candidate, from));
    }
    onStop(cb) {
        this.socket.on('stop', ({ from }) => cb(from));
    }
}
exports.default = Signaling;
