"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const webrtc_shim_1 = require("@dartnet/webrtc-shim");
class VideoConnection {
    constructor() {
        this.localStreams = [];
        this.remoteStreams = [];
        this.localIceCandidates = [];
        this.remoteIceCandidates = [];
        this.remoteStreamHandlers = [];
        this.connectionHandlers = [];
        this.candidateHandler = null;
        this.connected = false;
        this.connection = new webrtc_shim_1.RTCPeerConnection({
            iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
        });
        this.initRTCConnection();
        this.negotiationNeeded = new Promise((resolve) => {
            this.connection.addEventListener("negotiationneeded", (event) => {
                resolve();
            });
        });
    }
    initRTCConnection() {
        this.connection.addEventListener("icecandidate", (event) => {
            if (event.candidate) {
                if (this.candidateHandler) {
                    this.candidateHandler(event.candidate);
                }
                else {
                    this.localIceCandidates.push(event.candidate);
                }
            }
        });
        this.connection.addEventListener("track", (event) => {
            const stream = new webrtc_shim_1.MediaStream();
            stream.addTrack(event.track);
            this.remoteStreams.push(stream);
            console.log("TRACK");
            for (const handler of this.remoteStreamHandlers) {
                handler(this.remoteStreams);
            }
        });
        this.connection.addEventListener("connectionstatechange", (event) => {
            switch (this.connection.connectionState) {
                case "closed":
                case "disconnected":
                case "failed":
                    if (this.connected) {
                        this.connected = false;
                        for (const handler of this.connectionHandlers) {
                            handler(false);
                        }
                    }
                    break;
                case "connected":
                    this.connected = true;
                    for (const handler of this.connectionHandlers) {
                        handler(true);
                    }
                    break;
            }
        });
    }
    addTracks() {
        if (this.localStreams.length) {
            this.localStreams
                .filter(stream => !!stream)
                .forEach(stream => stream.getTracks()
                .forEach((track) => {
                console.log("add track", stream);
                this.connection.addTrack(track, stream);
            }));
        }
    }
    addTransceivers(numberOfVideoTransceivers) {
        console.log("add transceivers", numberOfVideoTransceivers);
        if (!numberOfVideoTransceivers)
            return;
        for (let i = 0; i < numberOfVideoTransceivers; i++) {
            this.connection.addTransceiver("video", {});
        }
    }
    addIceCandiate(candidate) {
        const iceCandidate = new webrtc_shim_1.RTCIceCandidate(candidate);
        if (this.connection.remoteDescription) {
            this.connection.addIceCandidate(iceCandidate);
            return;
        }
        this.remoteIceCandidates.push(iceCandidate);
    }
    startCall(options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("starting call", options);
            this.addTracks();
            this.addTransceivers(options.numberOfVideoTransceivers);
            try {
                yield this.negotiationNeeded;
                return this.createOffer(options);
            }
            catch (err) {
                console.error(err);
            }
        });
    }
    createOffer(options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            this.remoteStreams = [];
            this.remoteStreamHandlers = [];
            try {
                const offer = yield this.connection.createOffer({ offerToReceiveVideo: true });
                yield this.connection.setLocalDescription(offer);
                return offer;
            }
            catch (err) {
                console.error(err);
            }
        });
    }
    handleOffer(offer) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("handle offer", offer);
            yield this.connection.setRemoteDescription(new webrtc_shim_1.RTCSessionDescription(offer));
            this.addTracks();
            const answer = yield this.connection.createAnswer();
            if (answer) {
                yield this.connection.setLocalDescription(answer);
                for (const c of this.remoteIceCandidates) {
                    this.connection.addIceCandidate(c);
                }
                this.remoteIceCandidates = [];
                return answer;
            }
        });
    }
    handleAnswer(answer) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("handle answer", answer);
            try {
                yield this.connection.setRemoteDescription(new webrtc_shim_1.RTCSessionDescription(answer));
                for (const c of this.remoteIceCandidates) {
                    this.connection.addIceCandidate(c);
                }
                this.remoteIceCandidates = [];
            }
            catch (err) {
                console.error(err);
            }
        });
    }
    stopCall() {
        return __awaiter(this, void 0, void 0, function* () {
            this.connection.close();
            this.remoteStreams = [];
            for (const handler of this.remoteStreamHandlers) {
                handler(this.remoteStreams);
            }
        });
    }
    setVideoStreams(streams) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('setVideoStreams', streams);
            this.localStreams = streams;
        });
    }
    onRemoteStream(streamHandler) {
        if (this.remoteStreams) {
            streamHandler(this.remoteStreams);
        }
        this.remoteStreamHandlers.push(streamHandler);
    }
    onConnectionStateChanged(connectionHandler) {
        this.connectionHandlers.push(connectionHandler);
    }
    onIceCandidate(candidateHandler) {
        this.candidateHandler = candidateHandler;
        for (const candidate of this.localIceCandidates) {
            this.candidateHandler(candidate);
        }
    }
}
exports.default = VideoConnection;
