import { Camera } from "../interfaces";

interface Props {
  cameras: Camera[];
  selectedCamera?: string;
  onChange: (cam: string) => void;
}

export default (props: Props) => {
  return (
    <select
      name="camera"
      value={props.selectedCamera}
      onChange={(event) => props.onChange(event.target.value)}
      style={{
        width: 300,
        height: 30,
        marginTop: 20,
        alignSelf: "center",
      }}
    >
      {props.cameras.map((camera) => (
        <option key={camera.id} value={camera.id}>
          {camera.label}
        </option>
      ))}
      <option key="all" value="all">
        Alle Kameras
      </option>
    </select>
  );
};
